.Burger {
    background-color: #685959;
    color: white;
    text-align: left;
    padding: 8px 8px 8px 8px;
    position: fixed; /* or absolute */
    top: 0; /* Adjust as needed */
    left: 0; /* Adjust based on how you want it to slide in */
}


.Header h2 {
    color: white;
    text-decoration: none;
}

h2 {
    padding: 4px 0px 4px 0px;

}

#backArrow {
    padding: 4px 16px 4px 0px;
    margin: 8px 0px 0px 0px;
}