.Footer {
    bottom: 0;
    width: 100%;
    background-color: #816C5B;
    color: white;
}

.Footer h2 {
    color: white   ;
}

.Footer a {
    color: white;
    text-decoration: none;
}
.centered-label {
    display: flex;
    align-items: center; /* This should vertically center the children */
    margin: 4px 0px;
    gap: 12px;
    padding-left: 16px;
}

.centered-label h2, .centered-label a {
    margin: 0; /* Removes default margin from h2 and a */
    line-height: 1; /* Adjust this value as needed */
    display: flex;
    align-items: center; /* Ensures text is aligned if wrapped in another flex container */
}