
.service-menu ul {
    list-style-type: none; /* Removes default list styling */
}

ul {
    padding: 0 16px 0 16px; /* Adds some space around the list */
}

li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
    margin-bottom: 8px; /* Adds some space between list items */
    
}

h3 {
    margin-bottom: 0; /* Removes default margin */
    text-align: left;
    font-size: medium;
}

h2 {
    margin-bottom: 0; /* Removes default margin */
    text-align: left;
    font-size: medium;
}