/* Your component's CSS file */
.service-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Left-align labels */
}

.service-info div label {
    margin-bottom: 4px;
    /* Add spacing between label and input */
}

.service-info div input {
    width: 100%;
    /* Right-align input values */
}