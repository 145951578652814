.App {
  text-align: center;
  font-family: 'Domine', serif;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fokep {
  width: 70%;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  background-color: transparent;
  text-decoration: none;
}