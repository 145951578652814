img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
}

@media (min-width: 1200px) {
    img {
        width: 50%; /* or a specific smaller pixel value */
        height: auto;
    }
}
