.font-bold {
    font-style: italic;
    font-weight: lighter;
}

img {
    width: 70%;
    height: auto;
}

p {
    text-align: left;
}