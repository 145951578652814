h2 {
    padding: 8px 8px 8px 8px;
    color: #685959;
}

p {
    padding: 0px 8px 0px 8px;
    color: #685959;
    text-align: left;
    font-size: 18px;

}

.services-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}