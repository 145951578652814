.Header {
    background-color: #816C5B;
    padding: 8px 8px 8px 8px;
    color: white;
}

.main-header {
    color: white;
    text-decoration: none;
}

.burger {
    margin-right: 16px 16px;

}

.main-burger-logo {
    float: right;
    display: flex;
    vertical-align: bottom;
    
}

.main-logo {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    float: left;
    display: flex;
    vertical-align: bottom;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #F5F5F5;
}